<template>
    <div>
        <van-notice-bar mode="closeable" wrapable :scrollable="false" v-if="loadSuccess && !shop">
            当前店铺尚未审核通过，暂时无法发单，请等待工作人员审核。
        </van-notice-bar>
        <p style="font-size: 14px;text-indent: 16px;color: #aaa;margin: 0;padding: 0;line-height: 39px;">店铺团购</p>
        <van-list style="padding-bottom: 50px;">
            <van-card
                    v-for="(item,index) in list" :key="index"
                    :thumb="item.title_img"
                    :origin-price="item.price"
                    @click="$router.push({ path: '/shop/goods/create', query: { shop_id: shop_id,shop_name: shop_name,goods_id: item.goods_id }})">
                <template #title>
                    <div class="van-card__desc van-multi-ellipsis--l2"
                         style="font-size: 16px;line-height:30px;max-height: 60px;">{{item.goods_name}}
                    </div>
                </template>
                <template #tags>
                    <van-tag plain v-if="item.in_used">已锁定</van-tag>
                </template>
            </van-card>
        </van-list>
        <van-empty description="暂未设置团购" v-if="loadSuccess && !list.length" />

        <van-goods-action style="z-index: 999;background: none;">
            <van-button style="margin-left:10px;height: 40px;background: #fff;" round text="添加团购"  @click="$router.push({ path: '/shop/goods/create', query: { shop_id: shop_id,shop_name: shop_name }})" />
            <van-goods-action-button style="border-radius: 999px;" type="danger" :disabled="!shop || !list.length" @click="$router.push({ path: '/lottery/apply', query: { shop_id: shop_id}})" text="发布霸王餐"/>
        </van-goods-action>
    </div>
</template>

<script>
    import {Toast} from 'vant';

    export default {
        name: "GoodsList",
        data() {
            return {
                list: [],
                shop_id: 0,
                shop_name:'',
                loadSuccess:false,
                shop:false,
            }
        },
        mounted() {
            this.shop_id = this.$route.query.shop_id;
            this.shop_name = this.$route.query.shop_name;
            this.post('/api/client/shop/goods/list', {shop_id: this.shop_id}).then(result => {
                if (result.code === 0) {
                    this.list = result.data.goods;
                    this.shop = result.data.shop;
                } else {
                    Toast('加载失败');
                }
                this.loadSuccess = true;
            });
        }
    }
</script>

<style scoped>

</style>